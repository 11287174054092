import * as React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import './index.css'; // Import the CSS file

const App = () => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    React.useEffect(() => {
        document.title = 'Walton Walter';

        // Disable Ctrl + scroll zooming
        const handleWheel = (e) => {
            if (e.ctrlKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
            <div className="viewer-container">
                <Viewer
                    fileUrl={`${process.env.PUBLIC_URL}/cv.pdf`}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </div>
        </Worker>
    );
};

export default App;
